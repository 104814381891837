<template>
    <div class="popUpForm" v-loading="loading">
        <div class="content">
            <el-tabs v-model="activeName">
                <el-tab-pane name="form">
                    <span slot="label"><i class="iconfont iconbiaodan"></i>表单</span>
                    <formPage :form-data="code"></formPage>
                </el-tab-pane>
            </el-tabs>
        </div>
        <footer>
            <template v-if="isReadOnly == false">
                <el-button
                    type="primary"
                    class="save"
                    v-for="btn in btnList"
                    :key="btn.code"
                    :loading="btnLoading"
                    @click="save(btn.code)"
                >
                    {{ btn.name }}
                </el-button>
            </template>
            <el-button
                class="save"
                type="primary"
                :loading="saveBtnLoading"
                @click="save(2)"
                v-if="isReadOnly == false"
            >
                保存
            </el-button>
            <el-button class="Close" @click="Close">
                关闭
            </el-button>
        </footer>
    </div>
</template>

<script>
import store from '@/store';
import formPage from '@/components/form/formPage';

export default {
    components: {
        formPage,
    },
    props: {
        code: {
            type: [Array, Object, String],
            required: true, // 是否必传
        },
    },
    data() {
        return {
            // 加载中状态
            loading: false,
            // 默认展开tab页
            activeName: 'form',
            // 附件状态
            isDisabled: true,
            // 当前表单ID
            formId: '',
            // 表单是否只读状态,判断保存按钮是否显示
            isReadOnly: false,
            // 当前表单唯一标识
            thisFormOnly: '',
            // 按钮列表
            btnList: [],
            // 按钮点击
            statusSave: 0,
            // 保存按钮状态
            saveBtnLoading: false,
            btnLoading: false,
        };
    },
    created() {
        this.btnList = this.code.btnList;
        if (this.code.FormUrl) {
            // 表单是否只读状态,判断保存按钮是否显示
            if (this.$queryString(this.code.FormUrl, 'IsView')) {
                this.isReadOnly = true;
            } else {
                this.isReadOnly = false;
            }
            // 当前表单唯一标识赋值
            this.thisFormOnly = this.$queryString(this.code.FormUrl, 'FormCode');
        }
        if (this.code.FormKeyValue) {
            this.isDisabled = false;
            this.formId = this.code.FormKeyValue;
        }
    },
    methods: {
        // 保存表单
        save(status) {
            if (status === 1) {
                this.btnLoading = true;
            } else {
                this.saveBtnLoading = true;
            }
            this.statusSave = status;
            const iframe = window.document.getElementById('frameId' + this.thisFormOnly);
            iframe.contentWindow.JyFormSaveFormData(this.JTFlowAfterFormSaveSuccess, this.errorCallBack);
        },
        // 保存表单成功回调
        // eslint-disable-next-line complexity
        JTFlowAfterFormSaveSuccess(PKValues) {
            this.btnLoading = false;
            this.saveBtnLoading = false;
            this.isDisabled = false;
            this.formId = PKValues.PKValues;
            if (this.statusSave !== 2) {
                switch (this.statusSave) {
                    // 收款退回
                    case 'backProceeds':
                        this.backProceeds(this.formId);
                        break;
                    // 收款完成
                    case 'confirmProceeds':
                        this.confirmProceeds(this.formId);
                        break;
                }
            } else {
                this.updateTable();
            }
        },
        // 保存失败回调
        errorCallBack() {
            this.btnLoading = false;
            this.saveBtnLoading = false;
        },
        backProceeds(pkValues) {
            this.$axios
                .post(`/interfaceApi/sale/accounts/proceeds/back/${pkValues}`)
                .then(() => {
                    this.$parent.hide();
                    this.updateTable();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 收款确认
        confirmProceeds(pkValues) {
            // 拉取上期确认情况
            this.$axios
                .post(`/interfaceApi/sale/accounts/proceeds/check_complete/${pkValues}`)
                .then(resPre => {
                    if (resPre) {
                        this.$confirm(resPre, '提示', {
                            confirmButtonText: '确认',
                            cancelButtonText: '取消',
                            dangerouslyUseHTMLString: true,
                            type: 'warning',
                        }).then(() => {
                            this.confirmProceedsOver(pkValues);
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '收款确认取消...',
                            });
                        });
                    } else {
                        this.confirmProceedsOver(pkValues);
                    }

                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });

        },
        // 收款确认完成
        confirmProceedsOver(pkValues) {
            this.$axios
                .post('/interfaceApi/sale/accounts/proceeds/complete/' + pkValues)
                .then(res => {
                    if (!res) {
                        this.$message.success('确认错误！');
                    }
                    this.$parent.hide();
                    this.updateTable();
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },


        // 刷新列表
        updateTable() {
            store.state.currentOpenList.forEach(item => {
                item.tableObj.updateTable();
            });
        },
        // 取消添加关闭弹出层
        Close() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus"></style>
